import React from 'react'
import styled from 'styled-components'

import Text from './Text'

type Props = {
    images: any[]
}

const StyledList = styled.ol`
    margin 20px 40px;
`

const Toc = ({ images }: Props) => {
    return (
        <StyledList>
            {images.map((i, idx) => {
                const text = i.description || i.title
                return text ? (
                    <Text as="li" value={idx + 1}>
                        {text}
                    </Text>
                ) : null
            })}
        </StyledList>
    )
}

export default Toc
