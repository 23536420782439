import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import Link from './Link'
import { SharedContainer } from '../styledComponents'
import Text from './Text'
import { mq, spacing } from '../utils/presets'

const OFFSET = 30
const FixedContainer = styled.div`
    position: absolute;
    bottom: 0;
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    height: ${60 + OFFSET}px;
    ${mq.tablet} {
        min-height: ${90 + OFFSET}px;
    }
`
const Container = styled(SharedContainer)`
    position: relative;
    justify-content: center;
    align-items: center;
    border: 3px solid;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: ${60 + OFFSET}px;
    transition: bottom 500ms cubic-bezier(0.74, 2.24, 0.54, 0.48) 200ms;
    bottom: ${(props) =>
        props.isVisible ? `-${OFFSET}px` : `-${OFFSET + 60}px`};
    ${mq.tablet} {
        border: 4px solid;
        border-bottom: none;
        min-height: ${90 + OFFSET}px;
        bottom: ${(props) =>
            props.isVisible ? `-${OFFSET}px` : `-${OFFSET + 90}px`};
    }
    ${spacing(
        [
            [0, 0, 0],
            [10, 10, 60],
        ],
        'margin'
    )};
    padding-bottom: ${OFFSET}px;
`

type Props = {
    project: any
}

export default function NextProjectButton({ project }: Props) {
    const [ref, inView] = useInView({ threshold: 0.3 })
    return (
        <FixedContainer ref={ref}>
            <Link
                to={`/${project.fields.path}`}
                animationConfig={{
                    swipe: true,
                    duration: 1,
                    direction: 'left',
                }}
            >
                <Container isVisible={inView}>
                    <Text type="h3" normalNumeric>
                        {project.frontmatter.title}
                    </Text>
                </Container>
            </Link>
        </FixedContainer>
    )
}
