import React, { useContext } from 'react'

import Img from 'gatsby-image'
import styled from 'styled-components'

import Link from './Link'
import { getAllImagesByCategory_personalImages_edges_node } from '../pages/__generated__/getAllImagesByCategory'
import { shuffleArray } from '../utils/algo'
import { SeedContext } from '../../wrap-element'
import { mq, spacing } from '../utils/presets'

/**
 * CSS only Masonry like grid - even responsive!
 * Adapted from: https://css-tricks.com/seamless-responsive-photo-grid/
 * Also a solution (with ordering LTR): https://masonry-css-js.netlify.app/
 */
const Container = styled.div`
    // padding-top: 30px !important;
    // ${spacing([30, 40, 60], 'padding-top')}
    // padding-bottom: 90px;
    ${spacing([10, 10, 60], 'column-gap')}
    column-count: 2;
    ${mq.tablet} {
        column-count: 3;
    }
`

const Wrapper = styled.div`
    height: ${(props) =>
        props.aspectRatio < 1 ? 'calc((100vw - 30px) / 2)' : undefined};
    ${mq.tablet} {
        height: ${(props) =>
            props.aspectRatio < 1 ? 'calc((100vw - 40px) / 3)' : undefined};
    }
    ${mq.laptop} {
        height: ${(props) =>
            props.aspectRatio < 1 ? 'calc((100vw - 240px) / 3)' : undefined};
    }
    ${spacing([30, 40, 60], 'margin-bottom')}
    break-inside: avoid;
    page-break-inside: avoid;
`

const StyledImage = styled(Img)`
    max-height: ${(props) => (props.aspectRatio < 1 ? '100%' : undefined)};
    will-change: transform; // Necessary for Safari bug
`

const StyledImageWrapper = styled(Img)`
    break-inside: avoid;
    page-break-inside: avoid;
    will-change: transform; // Necessary for Safari bug
    // ${spacing([30, 40, 60], 'margin-bottom')}
`

type IGallery = {
    images: getAllImagesByCategory_personalImages_edges_node[]
    numberOfColumns?: number
    navigateToExtractor?: CallableFunction
    navigationAnimConfig?: any
    shuffle?: boolean
    className?: string
}

export default function Gallery({
    images,
    navigateToExtractor,
    navigationAnimConfig,
    shuffle = true,
    className,
}: IGallery) {
    const seed = useContext(SeedContext)
    const _images = shuffle ? shuffleArray(images, seed) : images

    const [hasMounted, setHasMounted] = React.useState(false)

    React.useEffect(() => {
        setHasMounted(true)
    }, [])

    if (!hasMounted && shuffle) {
        return null
    }

    return (
        <Container className={className}>
            {_images.map((i) => (
                <Wrapper
                    key={i.id}
                    aspectRatio={i.childImageSharp.fluid.aspectRatio}
                >
                    <Link
                        state={{ fromId: i.id }}
                        to={navigateToExtractor ? navigateToExtractor(i) : '/'}
                        animationConfig={navigationAnimConfig}
                    >
                        <StyledImage
                            aspectRatio={i.childImageSharp.fluid.aspectRatio}
                            imgStyle={{ objectFit: 'contain' }}
                            fluid={i.childImageSharp.fluid}
                        />
                    </Link>
                </Wrapper>
            ))}
        </Container>
    )
}
