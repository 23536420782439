import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import { getProject } from './__generated__/getProject'
import Icon from '../components/Icon'
import { SharedContainer } from '../styledComponents'
import NextProjectButton from '../components/NextProjectButton'
import Link from '../components/Link'
import { mq, spacing } from '../utils/presets'
import SEO from '../components/SEO'
import Toc from '../components/Toc'
import Text from '../components/Text'

const HeaderContainer = styled(SharedContainer)`
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid;
    min-height: 60px;
    padding: 0px 10px;
    ${mq.tablet} {
        border-bottom: 4px solid;
    }
    ${mq.laptop} {
        padding: 0px 60px;
    }
`

const TitleContainer = styled(Text)`
    flex: 1;
    justify-content: space-between;
    text-overflow: ellipsis;
`

const DescriptionContainer = styled.div`
    padding: 20px 10px;
    ${mq.laptop} {
        padding: 20px 60px;
        padding-right: calc(60px + (100vw - 240px) * (1 / 6));
    }
`

const StyledLink = styled(Link)`
    width: 18px;
    height: 18px;
    ${mq.tablet} {
        width: 24px;
        height: 24px;
    }
`

const StyledIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    ${mq.tablet} {
        width: 24px;
        height: 24px;
    }
`

const Hr = styled.hr`
    border: 0px;
    border-top: 3px solid;
    margin: 0px 10px;
    ${mq.tablet} {
        border-top: 4px solid;
    }
    ${mq.laptop} {
        margin: 0px 60px;
    }
`

export const GalleryContainer = styled.div`
    padding: 30px 10px;
    ${mq.tablet} {
        padding: 40px 10px;
    }
    ${mq.laptop} {
        padding: 60px 60px;
    }
    padding-bottom: ${(props) => (props.extraPadding ? '90px' : '')};
    ${mq.tablet} {
        padding-bottom: ${(props) => (props.extraPadding ? '130px' : '')};
    }
    ${mq.laptop} {
        padding-bottom: ${(props) => (props.extraPadding ? '150px' : '')};
    }
`

type Props = {
    data: getProject
}

export default function ProjectTemplate({
    data, // this prop will be injected by the GraphQL query below.
}: Props) {
    const { project, allProjectsOfCategory } = data

    // Project
    const { id, fields, excerpt, frontmatter, body } = project
    const { title, shouldRenderToc, images } = frontmatter
    const { path } = fields

    // Next project
    const projectIndex = allProjectsOfCategory.edges.findIndex(
        (p) => p.node.id === id
    )
    const nextProject =
        allProjectsOfCategory.edges.length > 1 &&
        allProjectsOfCategory.edges.length === projectIndex + 1
            ? allProjectsOfCategory.edges[0]
            : allProjectsOfCategory.edges[projectIndex + 1]

    const header = (
        <HeaderContainer>
            <TitleContainer type="h3" normalNumeric>
                {title}
            </TitleContainer>
            <StyledLink
                to={`/${path.split('/')[0]}`}
                animationConfig={{
                    swipe: true,
                    direction: 'down',
                    duration: 1,
                }}
            >
                <StyledIcon name="close" />
            </StyledLink>
        </HeaderContainer>
    )
    return (
        <Layout header={header}>
            <SEO
                title={title}
                image={images[0] && images[0].image.childImageSharp.fluid.src}
                description={excerpt}
            />
            {(shouldRenderToc || !!excerpt.length) && (
                <>
                    <DescriptionContainer>
                        {!!excerpt.length && <MDXRenderer>{body}</MDXRenderer>}
                        {shouldRenderToc && <Toc images={images} />}
                    </DescriptionContainer>
                    <Hr />
                </>
            )}
            <GalleryContainer extraPadding={!!nextProject}>
                <Gallery
                    images={images.map((i, index) => ({
                        index: index + 1,
                        ...i.image,
                    }))}
                    numberOfColumns={3}
                    navigateToExtractor={(image) => `/${path}/stream`}
                    navigationAnimConfig={{
                        swipe: true,
                        direction: 'up',
                        duration: 1,
                    }}
                    shuffle={false}
                />
            </GalleryContainer>
            {nextProject ? (
                <NextProjectButton project={nextProject.node} />
            ) : null}
        </Layout>
    )
}

export const pageQuery = graphql`
    query getProject($id: String, $category: String) {
        project: mdx(id: { eq: $id }) {
            id
            fields {
                path
            }
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                shouldRenderToc
                images {
                    description
                    image {
                        id
                        childImageSharp {
                            fluid(maxWidth: 400, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        allProjectsOfCategory: allMdx(
            filter: { fields: { category: { eq: $category } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        path
                    }
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`
